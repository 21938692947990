import React, { useEffect, useContext, useState, useCallback, Suspense, useRef } from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup'
import { useNavigate } from "react-router-dom";

// prime react
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Checkbox } from "primereact/checkbox";
import { SelectButton } from 'primereact/selectbutton';
import { Messages } from 'primereact/messages';

// user context
import { UserContext } from 'contexts/userContext';
import DisplayProductDetails from '_dashboard/modules/comparison/DisplayProductDetails';

// custom component
import DisplayInsuredDetails from '_dashboard/modules/comparison/DisplayInsuredDetails';
import DisplayComparisonTitle from '_dashboard/modules/comparison/DisplayComparisonTitle';
import FormInsurerDisplayedOption from '_dashboard/modules/comparison/FormInsurerDisplayedOption';
import LoadingScreen from 'components/LoadingScreen';
import DataTableFooter from '_dashboard/modules/comparison/DataTableFooter';

// custom hook
import useAxiosAuth from 'hooks/useAxiosAuth';
import useScreenSize from 'hooks/useScreenSize';

// custom helper method 
import sortArrayOfObjectsByKey from 'helpers/sortArrayOfObjectsByKey';
import formatDollar from 'helpers/formatDollar';
import scrollToTop from 'helpers/scrollToTop';

// import displayFormikLabel from 'helpers/displayFormikLabel'; // display label for valid and invalid field

// Whole life insurance comparison
import { insurer_wholeLife, userContext_wholeLife } from 'settings';

// dropdown values
const cover = [
  { label: "Hybrid (50% ECI + 50% CI)", cover: "HYBRID" },
  { label: "ECI (100% Acceleration)", cover: "ECI" },
  { label: "CI (100% Acceleration)", cover: "CI" },
];

const multiplier = [
  { label: "3X", multiplier: "3X" },
  { label: "4X", multiplier: "4X" },
  // { label: "5X", multiplier: "5X" },
];

const multiplierExpiryList = [
  // { label: 'Around Age 65', expiry: 65, key: 65 },
  { label: 'Around Age 70', expiry: 70, key: 70 },
  { label: 'Around Age 75', expiry: 75, key: 75 },
  { label: 'Around Age 80', expiry: 80, key: 80 },
  { label: 'Around Age 85', expiry: 85, key: 85 },
]

const PremiumTermList = [
  { label: '5 Pay', term: 5, key: 5 },
  { label: '10 Pay', term: 10, key: 10 },
  { label: '15 Pay', term: 15, key: 15 },
  { label: '20 Pay', term: 20, key: 20 },
  { label: '25 Pay', term: 25, key: 25 },
]

// formik
const initialValues = {
  cover: "HYBRID",
  multiplier: "3X", // "3X"
  multiplierExpiryList: [75, 80, 85],
  premiumTermList: [15],
}

// https://github.com/jquense/yup/issues/538
// formik validator with yup to check the value is not an empty string array based on active tab index
const validationSchema = yup.object({
  cover: yup.string().required("Please select a coverage type"),
  multiplier: yup.string().required("Please select a multiplier amount"),
  multiplierExpiryList: yup.array().test('is-not-empty', 'Please select at least one sum insured amount', value => value?.length > 0),
  premiumTermList: yup.array().test('is-not-empty', 'Please select at least one sum insured amount', value => value?.length > 0),
});

// options for the select view buttons
const viewOptions = [
  { icon: 'pi pi-book', value: 'default' },
  { icon: 'pi pi-dollar', value: 'values' },
  { icon: 'pi pi-calculator', value: 'kpis' },
];

const viewAge = [
  { name: 'Around Age 65', value: 65 },
  { name: 'Around Age 70', value: 70 },
  { name: 'Around Age 75', value: 75 },
  { name: 'Around Age 80', value: 80 },
  { name: 'Around Age 85', value: 85 }
];

// component
const CompareWholeLife = () => {

  // Ref for messages
  const messages = useRef(null);

  // state
  const [query, setQuery] = useState({});
  const [displayData, setDisplayData] = useState([]);
  const [readyToSearch, setReadyToSearch] = useState(true);
  const [showEditButton, setShowEditButton] = useState(false); // to display Edit button if the search is done
  const [loading, setLoading] = useState(false);

  // to select display policy values or KPIs
  const [view, setView] = useState('default');
  const [viewSelectAge, setViewSelectAge] = useState(65);

  // data-table header states
  const [expandedRows, setExpandedRows] = useState(null);
  const [mobileScreen, setMobileScreen] = useState(false)
  const [globalFilterValue2, setGlobalFilterValue2] = useState('');
  const [filters2, setFilters2] = useState({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  // user context
  const user = useContext(UserContext);

  // useEffect to check if insured details is in comparison context
  const navigate = useNavigate();

  // custom axios instance
  const axiosInstance = useAxiosAuth();

  // check screen size to decide what label to display
  const screenSize = useScreenSize();

  // https://www.paintcodeapp.com/news/ultimate-guide-to-iphone-resolutions (base on iphone 6+ 7+ & 8+)
  useEffect(() => {
    setMobileScreen(screenSize.width <= 600);
  }, [screenSize.width])

  useEffect(() => {
    // return to insured details page if insured details are not entered
    if (!user.comparisonInsured) {
      return navigate("/comparison/insuredDetails", { state: "from_CompareWholeLife" });
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // to activate after form is completed
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const res = await axiosInstance.post('dashboard/comparison/wholeLifePlan', query);
        // console.log("query", query, " | res.data", res?.data);

        if (res.data && res.data.success) {

          // ======================================================
          // Preparing the data from repsonse
          // ======================================================

          let premiumArr = res.data.result[0]; // array that contains all premium data point from mongo
          const productInfoArr = res.data.result[1];  // array that contains all product info for this category 
          // console.log("productInfoArr", productInfoArr)

          // sort premiumArr by premium (descending) follow by insurer name (ascending) name
          premiumArr = sortArrayOfObjectsByKey(premiumArr, "insurer", "asc");
          // console.log("---> premiumArr", premiumArr);

          // ======================================================
          // filter premiumArr by insurerList and create the final array to be displayed
          // ======================================================

          const combineArrData = premiumArr.map(plan => {

            // getting the insurer & plan name to match the corresponding product info
            const insurerName = plan.insurer.trim();
            const planName = plan.planName.trim();
            // console.log("---> insurerName", insurerName);
            // console.log("---> planName", planName);

            // to find the corresponding product in the productInfoArr
            const productInfo = productInfoArr.filter(p => p.insurerName === insurerName && p.planName === planName)// end filter
            // console.log("---> productInfo", productInfo);

            // ======================================================
            // create the display entry
            // ======================================================

            const entry = {
              ...plan,
              // customer promo details
              details: productInfo[0],
              promo: productInfo[0]?.customerPromo?.valid
            }

            plan?.kpi.forEach(kpi => {
              const keys = Object.keys(kpi) // getting all keys of the kpi object
              // storing all the kpi values into the entry object with respective age
              keys.forEach(key => { entry[`kpi${kpi.compareAtAge}_${key}`] = kpi[key] })
            })

            // console.log("---> entry", entry);
            return entry;
          })
          // console.log("---> combineArrData", combineArrData);

          const allowedInsurers = user?.comparisonInsurerDisplaySettings.find(ele => ele.comparisonType === userContext_wholeLife)?.onlyDisplayInsurers;
          // console.log("allowedInsurers", allowedInsurers);

          // filter out the insurers that are not allowed to be displayed
          // of the allowedInsurers is empty, display all insurers
          (allowedInsurers && allowedInsurers.length > 0)
            ? setDisplayData(() => combineArrData.filter(ele => allowedInsurers.includes(ele.insurer)))
            : setDisplayData(() => combineArrData); // use the mongoData if allowedInsurers.length === 0 (display all insurers)

          // setDisplayData(combineArrData);

          // close loading screen
          setLoading(false);
        } 
      } catch (error) {
        console.log(error);
        messages.current.show({ severity: 'error', detail: `Error: Backend server is down`, sticky: true });
        setLoading(false);
      }
    }
    // console.log("At useEffect", query);
    query.anb && getData();
  }, [axiosInstance, navigate, query, user])

  // formik
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values => handleSubmit(values)
  })

  const handleSubmit = (values) => {
    // clear current display table and show loading screen
    setDisplayData(() => []);
    // console.log("form values: ", values, " | user.comparisonInsured: ", user.comparisonInsured);

    setQuery({
      anb: user.comparisonInsured.ANB,
      ageNearestBirthday: user.comparisonInsured.AnB,
      gender: user?.comparisonInsured?.gender?.charAt(0),
      smoker: user?.comparisonInsured?.smokingStatus === "Non-Smoker" ? "NS" : "S",
      ...values
    });

    setReadyToSearch(false);
    setShowEditButton(true);
    scrollToTop();
  }

  const handleReset = () => {
    // formik.resetForm();
    formik.setFieldValue("premiumTermList", []);
    formik.setFieldValue("multiplierExpiryList", []);
    formik.setFieldValue("multiplier", "");
    formik.setFieldValue("cover", "");
    setDisplayData([]);
    setReadyToSearch(true);
    setShowEditButton(false);
  }

  const handleEdit = () => {
    setReadyToSearch(true);
    setShowEditButton(false);
  }

  const generateComparison = useCallback(() => {

    // functions to format data in columns
    const formatRef = (value) => `${value.multiplier}X-Age${value.multiplierExpiryAge}-${value.premiumTerm}Pay`;
    const formatPremiumTerm = (value) => value.premiumTerm + " Yrs";
    const formatBasicSumAssured = (value) => "$" + (value.basicSumAssured / 1000) + "K";
    const formatMultiplier = (value) => value.multiplier + "X";
    const formatMultiplierExpiryAge = (value) => "Age " + value.multiplierExpiryAge;
    const formatCoverType = (value) => value.coverType === "HYBRID" ? "Hybrid" : value.coverType;

    const formatEciSumAssured = (value) => {
      let factor = 0;
      switch (value?.coverType) {
        case "HYBRID":
          factor = 0.5;
          break;
        case "ECI":
          factor = 1;
          break;
        default:
          factor = 0;
      }
      return "$" + formatDollar(value?.basicSumAssured * value?.multiplier * factor / 1000) + "K";
    }
    const formatCiSumAssured = (value) => {
      let factor = 0;
      switch (value?.coverType) {
        case "HYBRID":
          factor = 0.5;
          break;
        case "CI":
          factor = 1;
          break;
        default:
          factor = 0;
      }
      return "$" + formatDollar(value?.basicSumAssured * value?.multiplier * factor / 1000) + "K";
    }
    const formatCurrency = (value) => value?.annualPremium?.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    const formatPromo = (value) => value?.promo ? "Y" : "";

    // Values View 
    const formatSaPlusRB = (value) => value[`kpi${viewSelectAge}_deathCoverWithRB`] ? "$" + formatDollar(value[`kpi${viewSelectAge}_deathCoverWithRB`]) : "";
    const formateffectiveCover = (value) => value[`kpi${viewSelectAge}_deathEffectiveSumInsured`] ? "$" + formatDollar(value[`kpi${viewSelectAge}_deathEffectiveSumInsured`]) : "";
    const formatGuaranteedSV = (value) => value[`kpi${viewSelectAge}_cashValueGuranteed`] ? "$" + formatDollar(value[`kpi${viewSelectAge}_cashValueGuranteed`]) : "";
    const formatTotalSV = (value) => value[`kpi${viewSelectAge}_cashValueTotal`] ? "$" + formatDollar(value[`kpi${viewSelectAge}_cashValueTotal`]) : "";

    // function to expand all and collapse all expanders
    const collapseAll = () => setExpandedRows(null);

    const expandAll = () => {
      let _expandedRows = {};
      displayData.forEach(p => _expandedRows[`${p._id}`] = true);
      setExpandedRows(_expandedRows);
    }

    // filter function for key search
    const onGlobalFilterChange2 = (e) => {
      const value = e.target.value;
      let _filters2 = { ...filters2 };
      _filters2['global'].value = value;

      setFilters2(_filters2);
      setGlobalFilterValue2(value);
    }

    // function to display icon for select button
    const displayIcon = (option) => {
      return <i className={option.icon}></i>;
    }

    const renderHeader = () => <>
      <div className="flex justify-content-between">
        <span>
          <Button icon="pi pi-plus" label={mobileScreen ? "" : "Expand All"} onClick={expandAll} className="mr-2" />
          <Button icon="pi pi-minus" label={mobileScreen ? "" : "Collapse All"} onClick={collapseAll} />
        </span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            placeholder={mobileScreen ? "Search" : "Keyword Search"}
            className={mobileScreen ? "p-inputtext-sm" : ""}
            value={globalFilterValue2}
            onChange={onGlobalFilterChange2}
          />
        </span>
        <span>
          <SelectButton value={view} onChange={e => setView(() => e?.target?.value)} itemTemplate={displayIcon} optionLabel="value" options={viewOptions} className='inline' unselectable={false} />
        </span>
      </div>
      {
        view !== "default" && <div className="flex justify-content-end mt-2">
          <Dropdown value={viewSelectAge} onChange={(e) => setViewSelectAge(e.value)} options={viewAge} optionLabel="name" placeholder="Compare at Age" className="w-full md:w-12rem" />
        </div>
      }
    </>

    const rowExpansionTemplate = (data) => <DisplayProductDetails data={data} />

    return <div className='col-12'>

      <div className='card'>
        <DisplayComparisonTitle type="Whole Life Insurance" />
        <Suspense fallback={<div>Loading Comparison Table...</div>}>
          <DataTable value={displayData}
            stripedRows
            responsiveLayout="scroll"

            // header
            header={renderHeader()}

            // footer
            footer={<DataTableFooter type="wholeLife" query={query} />}

            // expander props
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            rowExpansionTemplate={rowExpansionTemplate}
            dataKey="_id"

            // keyword search props
            filters={filters2} filterDisplay="row"

          >
            <Column expander style={{ width: '3em' }} />
            <Column field="insurer" header="Insurer" sortable></Column>
            {view !== "default" && <Column field="" header="Ref" body={formatRef}></Column>}

            {view === "default" && <Column field="plan_shortName" header="Plan Name" sortable></Column>}
            {view === "default" && <Column field="basicSumAssured" header="Basic SA" body={formatBasicSumAssured} sortable></Column>}
            {view === "default" && <Column field="multiplier" header="Multiplier" body={formatMultiplier} sortable></Column>}
            {view === "default" && <Column field="multiplierExpiryAge" header="Multi. Expiry" body={formatMultiplierExpiryAge} sortable></Column>}
            {view === "default" && <Column field="coverType" header="Type" body={formatCoverType} sortable></Column>}
            {view === "default" && <Column field="" header="ECI/CI SA" body={formatEciSumAssured} sortable></Column>}
            {view === "default" && <Column field="" header="CI SA" body={formatCiSumAssured} sortable></Column>}

            {view === "values" && <Column field={`kpi${viewSelectAge}_deathCoverWithRB`} header={`SA + RB (${viewSelectAge})`} body={formatSaPlusRB} sortable></Column>}
            {view === "values" && <Column field={`kpi${viewSelectAge}_deathEffectiveSumInsured`} header={`Effective Cover (${viewSelectAge})`} body={formateffectiveCover} sortable></Column>}
            {view === "values" && <Column field={`kpi${viewSelectAge}_cashValueGuranteed`} header={`Guar SV (${viewSelectAge})`} body={formatGuaranteedSV} sortable></Column>}
            {view === "values" && <Column field={`kpi${viewSelectAge}_cashValueTotal`} header={`Total SV (${viewSelectAge})`} body={formatTotalSV} sortable></Column>}

            {view === "kpis" && <Column field={`kpi${viewSelectAge}_perDollarDeathBenefit_Multiplier`} header={`Multi. SA Per Dollar`} sortable></Column>}
            {view === "kpis" && <Column field={`kpi${viewSelectAge}_perDollarDeathBenefit_KPI_BasicRB`} header={`Basic SA + RB Per Dollar (${viewSelectAge})`} sortable></Column>}
            {view === "kpis" && <Column field={`kpi${viewSelectAge}_perDollarDeathBenefit_KPI_EffectiveSumInsured`} header={`Effective SA Per Dollar (${viewSelectAge})`} sortable></Column>}
            {view === "kpis" && <Column field={`kpi${viewSelectAge}_perDollarCashValueBenefit_Guraranteed`} header={`Guar. SV Per Dollar (${viewSelectAge})`} sortable></Column>}
            {view === "kpis" && <Column field={`kpi${viewSelectAge}_perDollarCashValueBenefit_Total`} header={`Total SV Per Dollar (${viewSelectAge})`} sortable></Column>}

            {view === "default" && <Column field="premiumTerm" header="Prem. Term" body={formatPremiumTerm} sortable></Column>}
            <Column field="annualPremium" header="Annual Premium" body={formatCurrency} sortable></Column>
            {view === "default" && <Column field="promo" header="Promo" body={formatPromo} sortable></Column>}
          </DataTable>
        </Suspense>
      </div>
    </div>

  }, [displayData, expandedRows, filters2, globalFilterValue2, mobileScreen, view, viewSelectAge, query])

  // checkbox formilk functions
  // check if the box's value is selected based on formik values (formik.values.premiumTermList)
  const isPremiumTermChecked = amt => (formik.values.premiumTermList).some((item) => item === amt);
  const isExpiryChecked = amt => (formik.values.multiplierExpiryList).some((item) => item === amt);

  // check if the select amount is in the list
  const handlePremiumListChange = amt => (formik.values.premiumTermList).some((item) => item === amt)
    ? formik.setFieldValue("premiumTermList", formik.values.premiumTermList.filter((item) => item !== amt)) // if yes, remove from list
    : formik.setFieldValue("premiumTermList", [...formik.values.premiumTermList, amt]); // if no, add to list

  const handleExpiryListChange = amt => (formik.values.multiplierExpiryList).some((item) => item === amt)
    ? formik.setFieldValue("multiplierExpiryList", formik.values.multiplierExpiryList.filter((item) => item !== amt)) // if yes, remove from list
    : formik.setFieldValue("multiplierExpiryList", [...formik.values.multiplierExpiryList, amt]); // if no, add to list

  return (
    <div className='grid'>
      {
        !readyToSearch && generateComparison()
      }
      <div className='col-12 md:col-6'>
        <div className='card'>
          <Messages ref={messages} />
          <h5>Select coverage type</h5>
          {(formik.touched && formik.errors) && <p className='p-error block font-medium'>{formik.errors.cover}</p>}
          <Dropdown value={formik.values.cover} options={cover} onChange={e => formik.setFieldValue("cover", e.value)} optionLabel="label" optionValue="cover" className='w-full' disabled={!readyToSearch} />

          <h5>Select multiplier amount</h5>
          {(formik.touched && formik.errors) && <p className='p-error block font-medium'>{formik.errors.multiplier}</p>}
          <Dropdown value={formik.values.multiplier} options={multiplier} onChange={e => formik.setFieldValue("multiplier", e.value)} optionLabel="label" optionValue="multiplier" className='w-full' disabled={!readyToSearch} />

          <h5>Select multiplier expiry</h5>
          {(formik.touched && formik.errors) && <p className='p-error block font-medium'>{formik.errors.multiplierExpiryList}</p>}
          {multiplierExpiryList.map((multiplierExpiry) => {
            return (
              <div key={multiplierExpiry.key} className="field-checkbox">
                <Checkbox
                  inputId={multiplierExpiry.key}
                  value={multiplierExpiry.expiry}
                  checked={isExpiryChecked(multiplierExpiry.expiry)}
                  disabled={!readyToSearch}
                  onChange={e => handleExpiryListChange(e.value)} />
                <label htmlFor={multiplierExpiry.key} className="ml-2">
                  {multiplierExpiry.label}
                </label>
              </div>
            );
          })}

          <h5>Select premium payment term</h5>
          {(formik.touched && formik.errors) && <p className='p-error block font-medium'>{formik.errors.premiumTermList}</p>}
          {PremiumTermList.map((premiumTerm) => {
            return (
              <div key={premiumTerm.key} className="field-checkbox">
                <Checkbox
                  inputId={premiumTerm.key}
                  value={premiumTerm.term}
                  checked={isPremiumTermChecked(premiumTerm.term)}
                  disabled={!readyToSearch}
                  onChange={e => handlePremiumListChange(e.value)} />
                <label htmlFor={premiumTerm.key} className="ml-2">
                  {premiumTerm.label}
                </label>
              </div>
            );
          })}

          <div className='mt-5'>
            <hr></hr>
            <div className='grid mt-3'>
              <div className={showEditButton ? 'col-12 md:col-4' : 'col-12 md:col-6'}>
                <Button type="button" label="Reset" aria-label="Reset" className='w-full p-button-outlined' onClick={handleReset} />
              </div>
              {
                showEditButton && <div className='col-12 md:col-4'>
                  <Button type="button" label="Edit" aria-label="Reset" className='w-full p-button-secondary' onClick={handleEdit} />
                </div>
              }
              <div className={showEditButton ? 'col-12 md:col-4' : 'col-12 md:col-6'}>
                <Button type="submit" label="Search" aria-label="Search" className='w-full' disabled={!readyToSearch} onClick={formik.handleSubmit} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-12 md:col-6'>
        <DisplayInsuredDetails />
        <FormInsurerDisplayedOption comparisonType={userContext_wholeLife} insurerList={insurer_wholeLife} />
      </div>
      {loading && <LoadingScreen />}
    </div>
  )
}

export default CompareWholeLife