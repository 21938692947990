import React from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'

// prime react
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { Panel } from 'primereact/panel';
import { InputTextarea } from 'primereact/inputtextarea';

// helper function  
import displayFormikLabel from 'helpers/displayFormikLabel'; // display label for valid and invalid field
import { isoTimeStampToDate } from 'helpers/isoTimeStampToDateFormat';

// custom hooks
import useAxiosAuth from 'hooks/useAxiosAuth';

const extendExpiryOptions = [
    { label: 'Not Needed', value: 0 },
    { label: '2 Weeks', value: 14 },
    { label: '1 Week', value: 7 },
    { label: '3 Days', value: 3 },
    { label: '1 Day', value: 1 },
];

// const regex = /^[A-Za-z0-9 .,'!&()->\n]+$/;
const regex = /^[A-Za-z0-9 .,'!&()%\n-]+$/;

const findInvalidChars = (str) => {
    const invalidChars = [];
    for (let char of str) {
        if (!regex.test(char)) {
            invalidChars.push(char);
        }
    }
    return invalidChars;
};

const validationSchema = yup.object().shape({

    clientName: yup.string().required("Client's name on comparison is required")
        .test('is-valid', function (value) {
            const { path, createError } = this;
            const invalidChars = findInvalidChars(value || '');
            if (invalidChars.length > 0) {
                return createError({ path, message: () => `Invalid characters: ${invalidChars.join(', ')}` });
            }
            return true;
        }),
    clientDescription: yup.string().required('Client description on advisor portal is required')
        .test('is-valid', function (value) {
            const { path, createError } = this;
            const invalidChars = findInvalidChars(value || '');
            if (invalidChars.length > 0) {
                return createError({ path, message: () => `Invalid characters: ${invalidChars.join(', ')}` });
            }
            return true;
        }),
    advisorRecommendation: yup.string().required('Advisor recommendation is required')
        .test('is-valid', function (value) {
            const { path, createError } = this;
            const invalidChars = findInvalidChars(value || '');
            if (invalidChars.length > 0) {
                return createError({ path, message: () => `Invalid characters: ${invalidChars.join(', ')}` });
            }
            return true;
        }),
    extend: yup.number().required('Required').min(0, 'Select extension period').max(14, 'Max extension period is 14 days'),
});

const EditComparisonDetails = (props) => {

    // console.log("@ EditComparisonDetails -> props:", props);

    const { comparisonData, setEditMode, refresh } = props
    const { _id, clientName, clientDescription, expiresAt, advisorRecommendation } = comparisonData;
    // console.log(`@ EditComparisonDetails -> clientName: ${clientName} | clientDescription: ${clientDescription} | expiresAt: ${expiresAt} | _id: ${_id}`);

    const initialValues = { clientName, clientDescription, advisorRecommendation, extend: 0 };

    // custom axios instance
    const axiosInstance = useAxiosAuth();

    const handleUpdate = async (values) => {

        // console.log("@ EditComparisonDetails -> handleUpdate -> values:", values);
        const { clientName, clientDescription, extend, advisorRecommendation } = values;
        // console.log("id", _id)

        // todo: update the comparison details in the database
        try {
            const res = await axiosInstance.post('dashboard/comparisonUpload/editComparison', {
                id: _id, clientName, clientDescription, advisorRecommendation, extend
            });

            if (res.data && res.data?.success) {
                setEditMode(false);
                refresh();
            } else {
                alert("Error updating comparison details, please click [Cancel] and try again");
            }
        } catch (error) {
            alert("Error updating comparison details, please click [Cancel] and try again");
        }
    }

    const handleCancel = () => setEditMode(false);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => handleUpdate(values)
    });

    // console.log("@ EditComparisonDetails -> formik values:", formik.values);

    return <div className='card'>
        <h5>Edit Comparison Details</h5>
        <div className='mt-3'>

            <Panel header="Client's Name (Display on the online comparison)" className='mb-4 shadow-3'>
                {displayFormikLabel("clientName", "", formik.touched, formik.errors)}
                <InputText id="clientName" value={formik.values.clientName} className="w-full mb-2" onChange={e => formik.setFieldValue("clientName", e.target.value)} />
            </Panel>

            <Panel header="Client's Name (Display on advisor portal)" className='mb-4 shadow-3'>
                {displayFormikLabel("clientDescription", "", formik.touched, formik.errors)}
                <InputText id="clientDescription" value={formik.values.clientDescription} className="w-full mb-2" onChange={e => formik.setFieldValue("clientDescription", e.target.value)} />
            </Panel>

            <Panel header={`Expires On: ${isoTimeStampToDate(expiresAt, " ", true)}`} className='mb-4 shadow-3'>
                {displayFormikLabel("extend", "Extend by:", formik.touched, formik.errors)}
                <Dropdown
                    id="extend"
                    value={formik.values.extend}
                    options={extendExpiryOptions}
                    onChange={e => formik.setFieldValue("extend", e.value)}
                    placeholder="Select extension period"
                    className="w-full mb-2"
                />
            </Panel>

            <Panel header="Recommendation" className='mb-4 shadow-3'>
                {displayFormikLabel("advisorRecommendation", "Extend by:", formik.touched, formik.errors)}
                <InputTextarea value={formik.values.advisorRecommendation} onChange={(e) => formik.setFieldValue("advisorRecommendation", e.target.value)} rows={5} className='w-full mb-3' />
            </Panel>

            <Divider />
            <div className='flex flex flex-row-reverse'>
                <Button type="button" label="Update" onClick={formik.handleSubmit} />
                <span className='mr-3'></span>
                <Button type="button" label="Cancel" className='p-button-outlined' onClick={handleCancel} />
            </div>
        </div>
    </div>
}

export default EditComparisonDetails