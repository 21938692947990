import React, { useState } from 'react'

// prime react
import { Button } from 'primereact/button';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup'
import { InputTextarea } from 'primereact/inputtextarea';

// custom components
import EditComparisonDetails from './EditComparisonDetails';

// customed helper function
import { isoTimeStampToDate } from 'helpers/isoTimeStampToDateFormat';

// custom hook
import useAxiosAuth from 'hooks/useAxiosAuth';

// service url
import { clientComparisonURL } from 'settings';


// helper function
const displayDOB = (dob) => {
    if (!dob) return "";
    const date = new Date(dob);
    return `DOB: ${date.toLocaleDateString()}`;
}

const ComparisonDetails = (props) => {

    // edit mode to display the form to edit the comparison details
    const [editMode, setEditMode] = useState(false);

    const { refresh } = props;
    const { clientName, clientALB, clientSmoker, clientDOB, lastViewed, createdAt, uniqueID, disabled, _id, advisorRecommendation } = props?.data || {};
    // console.log("@ ComparisonDetails -> props:", props);

    const rnf = uniqueID?.split("-")[0].trim();
    const uniqueLink = `${clientComparisonURL}/${rnf}/${_id}`;

    // axios instance
    const axiosInstance = useAxiosAuth();

    // ===
    // Disable Comparison
    // ===

    const disableCompaison = async (uniqueID) => {
        try {
            await axiosInstance.post(`dashboard/comparisonUpload/disableComparison`, { uniqueID });
        } catch (error) {
            console.log(error);
            console.log("Error Disabling Comparison");
        }
    }

    const handleDisable = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Please confirm to disable. Once disabled, Cannot Undo.',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                disableCompaison(uniqueID);
                refresh();
            },
            reject: () => { }
        });
    }

    // ===
    // Edit Comparison
    // ===

    const handleEdit = async (comparisonID) => setEditMode(true);


    return editMode
        ? <EditComparisonDetails setEditMode={setEditMode} comparisonData={props.data} refresh={props.refresh} />
        : <div className='card'>
            <div>
                <h5 className='mb-3'>Insured Details:</h5>
                <ul>
                    <li>Insured Name: {clientName}</li>
                    {clientDOB && <li>{displayDOB(clientDOB)}</li>}
                    <li>{`ALB: ${clientALB}`}</li>
                    <li>{`Smoker: ${clientSmoker ? "Yes" : "No"}`}</li>
                    <li>Generated on: {isoTimeStampToDate(createdAt, " ", true)}</li>
                    <li>Link: <a
                        href={uniqueLink}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {uniqueLink}
                    </a></li>

                </ul>
            </div>
            <div className='mt-6'>
                <h5 className='mb-3'>{`View History (Comparison Loaded ${lastViewed.length} ${lastViewed.length > 1 ? "times" : "time"})`}</h5>
                <ol>
                    {lastViewed.map((view, index) => <li key={index}>{isoTimeStampToDate(view, " ", true)}</li>)}
                </ol>
            </div>
            <div className='mt-6'>
                <h5 className='mb-3'>Recommendation</h5>
                <InputTextarea autoResize={true} value = {advisorRecommendation} disabled={true} className="w-full"/>
            </div>
            <div className='mt-6'>
                <div className='flex flex-row-reverse'>
                    {
                        !disabled &&
                        <>
                            <Button
                                icon="pi pi-pencil"
                                label="Edit"
                                onClick={() => handleEdit(uniqueID)}
                            />
                            <span className='mr-3'></span>
                            <Button
                                icon="pi pi-exclamation-triangle"
                                label="Disable"
                                className='p-button-outlined'
                                onClick={handleDisable}
                            ></Button>
                        </>
                    }
                    <ConfirmPopup />
                </div>
            </div>
        </div>
}

export default ComparisonDetails