// to be used in DeathCoverageCalculator.js
// to collect client details for death coverage calculator

import React, { useState, useContext, useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup'

// prime react
import { TabView, TabPanel } from 'primereact/tabview';
import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';

// custom component
import DisplayInsuredDetails from '_dashboard/modules/comparison/DisplayInsuredDetails';

// user context
import { UserContext, DispatchUserContext } from 'contexts/userContext';

// custom useLocalStorge hook
import useLocalStorage from 'hooks/useLocalStorage';

// custom helper method 
import displayFormikLabel from 'helpers/displayFormikLabel'; // display label for valid and invalid field
import calculateAge from 'helpers/calculateAge';
import getPrimeDate from 'helpers/getPrimeDate';

// variable not needed for rendering
const today = new Date();
const genders = ["Female", "Male"];
const smokingStatus = ["Non-Smoker", "Smoker"];
const numberDependents = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((num) => ({ label: num.toString(), value: num }));

// component
const ClientDetails = ({ clientDetails, setClientDetails }) => {

    // custom localStorage hook
    const [localInsuredDetails, setLocalInsuredDetails] = useLocalStorage(`comparison-InsuredDetails`, null);
    // console.log("localInsuredDetails");
    // console.log(localInsuredDetails);

    // context
    const dispatch = useContext(DispatchUserContext);
    const user = useContext(UserContext);
    // console.log("user: @ calcule coverage clientDetails", user);

    // state
    const [activeTabIndex, setActiveTabIndex] = useState((!user?.insuredDetails?.dob && user?.insuredDetails?.alb) ? 0 : 1); // 0 for DOB, 1 for ALB

    // accessing locations (history of previous routes)
    const location = useLocation();

    // react router navigate to previous page (back button)
    const navigate = useNavigate();

    // formik
    const initialValues = localInsuredDetails
        ? {
            name: localInsuredDetails.name,
            gender: localInsuredDetails.gender,
            smokingStatus: localInsuredDetails.smokingStatus,
            dob: localInsuredDetails.dob ? getPrimeDate(localInsuredDetails.dob) : "",
            alb: localInsuredDetails.ALB,
            numberDependents: localInsuredDetails.numberDependents || 0,
            maxYearsSupport: localInsuredDetails.maxYearsSupport || 1,
        }
        : {
            name: "Valued Client",
            gender: "Female",
            smokingStatus: "Non-Smoker",
            dob: "",
            alb: 40,
            numberDependents: 0,
            maxYearsSupport: 1,
        }


    const validationSchema = yup.object({
        name: yup.string().required("Please enter insured's name."),
        gender: yup.string().required("Please enter insured's gender."),
        smokingStatus: yup.string().required("Please select smoking status"),
        dob: activeTabIndex === 0 ? yup.string().typeError("Please enter date in DD/MM/YYYY").required("Please select insured's date of birth") : yup.string(),
        alb: activeTabIndex === 1 ? yup.number().required("Please enter insured's current age").min(0, "Minimum 0").max(100, "Allowed maximum is 100") : yup.number(),
        numberDependents: yup.number().required("Please select number of dependents").min(0, "Minimum 0").max(12, "Maximum 12"),
        maxYearsSupport: yup.number("Please enter the max number of years to support. Minimum 1 year").required("Please enter max years to support").min(1, "Minimum 1 year").max(99, "Allowed maximum is 99")
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => handleSubmit(values)
    });


    const handleSubmit = (values) => {

    }

    const handleReset = () => {

    }

    return <div className='card'>
        <div className='grid m-3'>
            <div className='col-12'>
                <div className='card'>
                    <h5>Input Client's Details for coverage calculation</h5>
                    <p>2 ways to input insured's age</p>
                    <ol>
                        <li>Date of birth (D.O.B)</li>
                        <li>Age last birthday (ALB)</li>
                    </ol>
                    <p>Other required fields</p>
                    <ul>
                        <li>Name</li>
                        <li>Gender</li>
                        <li>Smoking status</li>
                    </ul>
                </div>
            </div>
            <div className='col-12 md:col-6'>
                <div className='card'>
                    <h5>Enter Client's details</h5>
                    <TabView activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)}>
                        <TabPanel header="By D.O.B">
                            {displayFormikLabel("dob", "Select or key in date (DD/MM/YYYY):", formik.touched, formik.errors)}
                            <Calendar id="dob" dateFormat="dd/mm/yy" maxDate={today}
                                value={formik.values.dob} onChange={(e) => formik.setFieldValue("dob", e.value)} showWeek showButtonBar className="w-full mb-3" />
                        </TabPanel>
                        <TabPanel header="By ALB">
                            {displayFormikLabel("alb", "Insured's age last birthday:", formik.touched, formik.errors)}
                            <InputNumber id="alb" showButtons min="0" max="99" step="1" value={formik.values.alb} onChange={(e) => formik.setFieldValue("alb", e.value)} className="w-full mb-4" placeholder="Current age" />
                        </TabPanel>
                    </TabView>
                    {displayFormikLabel("name", "Name of insured:", formik.touched, formik.errors)}
                    <InputText id="name" type="text" value={formik.values.name} onChange={(e) => formik.setFieldValue("name", e.target.value)} className="w-full mb-4" placeholder="Insured's name" />

                    {displayFormikLabel("gender", "Gender of insured:", formik.touched, formik.errors)}
                    <SelectButton id="gender" value={formik.values.gender} options={genders} onChange={(e) => formik.setFieldValue("gender", e.value)} className="mb-4" />

                    {displayFormikLabel("smokingStatus", "Smoking Status:", formik.touched, formik.errors)}
                    <SelectButton id="smokingStatus" value={formik.values.smokingStatus} options={smokingStatus} onChange={(e) => formik.setFieldValue("smokingStatus", e.value)} className="mb-4 w-full" />

                    {displayFormikLabel("numberDependents", "Number of dependents:", formik.touched, formik.errors)}
                    <Dropdown id="numberDependents" value={formik.values.numberDependents} options={numberDependents} onChange={(e) => formik.setFieldValue("numberDependents", e.value)} placeholder="Select number of dependents" className={`w-full mb-${formik.values.numberDependents > 0 ? 4 : 5}`} optionLabel="label"/>

                    {
                        formik.values.numberDependents > 0 &&
                        <>
                            {displayFormikLabel("maxYearsSupport", "Max number of years to support:", formik.touched, formik.errors)}
                            <InputNumber id="maxYearsSupport" showButtons min="1" max="99" step="1" value={formik.values.maxYearsSupport} onChange={(e) => formik.setFieldValue("maxYearsSupport", e.value)} className="w-full mb-4" placeholder="Max years to support" />
                        </>
                    }

                    <hr></hr>

                    <div className='grid mt-2'>
                        <div className='col-12 md:col-6'>
                            <Button type="button" label="Reset" aria-label="Reset" className='w-full p-button-outlined' onClick={handleReset} />
                        </div>
                        <div className='col-12 md:col-6'>
                            <Button type="submit" label="Submit" aria-label="Submit" className='w-full' onClick={formik.handleSubmit} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
}

export default ClientDetails