import React, { useState, useEffect, useContext, useCallback, Suspense, useRef } from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup'
import { useNavigate } from "react-router-dom";

// prime react
import { TabView, TabPanel } from 'primereact/tabview';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { Messages } from 'primereact/messages';

// comparison context
import { UserContext } from 'contexts/userContext';

// custom component
import DisplayInsuredDetails from '_dashboard/modules/comparison/DisplayInsuredDetails';
import DisplayProductDetails from '_dashboard/modules/comparison/DisplayProductDetails';
import DisplayComparisonTitle from '_dashboard/modules/comparison/DisplayComparisonTitle';
import FormInsurerDisplayedOption from '_dashboard/modules/comparison/FormInsurerDisplayedOption';
import DataTableFooter from '_dashboard/modules/comparison/DataTableFooter';

// custom hook
import useAxiosAuth from 'hooks/useAxiosAuth';
import useScreenSize from 'hooks/useScreenSize';

// custom helper method 
import displayFormikLabel from 'helpers/displayFormikLabel'; // display label for valid and invalid field
import formatDollar from 'helpers/formatDollar';
import sortArrayOfObjectsByKey from 'helpers/sortArrayOfObjectsByKey';
import scrollToTop from 'helpers/scrollToTop';

// long term care comparison insurers
import { insurer_longTermCare, userContext_longTermCare } from 'settings';

const initialValues = {
    searchBySA: true,
    sumInsured: "3000",
    premium: "600",
    _2ADLs: true,
    _3ADLs: true,
    _escalation: false,
}

const CompareLongTermCare = () => {

    // Ref for messages
    const messages = useRef(null);

    // user context
    const user = useContext(UserContext);

    // useEffect to check if insured details is in comparison context
    const navigate = useNavigate();

    // custom axios instance
    const axiosInstance = useAxiosAuth();

    //state
    const [activeTabIndex, setActiveTabIndex] = useState(0); // 0 for DOB, 1 for ALB
    const [searchBySA, setSearchBySA] = useState(true);
    const [mongoData, setMongoData] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [readyToSearch, setReadyToSearch] = useState(true);
    const [showEditButton, setShowEditButton] = useState(false); // to display Edit button if the search is done
    const [lastSearchParameter, setLastSearchParameter] = useState({});
    const [loading, setLoading] = useState(true);
    // const [query, setQuery] = useState({});

    // data-table header states
    const [expandedRows, setExpandedRows] = useState(null);
    const [mobileScreen, setMobileScreen] = useState(false)
    const [globalFilterValue2, setGlobalFilterValue2] = useState('');
    const [filters2, setFilters2] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    // check screen size to decide what label to display
    const screenSize = useScreenSize();

    // https://www.paintcodeapp.com/news/ultimate-guide-to-iphone-resolutions (base on iphone 6+ 7+ & 8+)
    useEffect(() => {
        setMobileScreen(screenSize.width <= 600);
    }, [screenSize.width])

    useEffect(() => {
        if (!user.comparisonInsured) {
            return navigate("/comparison/insuredDetails", { state: "from_CompareTerm" });
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // loading all data once when the page is loaded
    useEffect(() => {

        // async fucntion in useeffect
        const getLongTermCareData = async () => {
            try {
                // console.log(user?.comparisonInsured?.ANB + " | " + user?.comparisonInsured?.gender)
                const gender = user?.comparisonInsured?.gender === "Male" ? "M" : "F";
                const res = await axiosInstance.post('dashboard/comparison/longTermCare', { anb: user?.comparisonInsured?.ANB, gender });
                //console.log("res in useEffect", res);

                // to ensure result contains premium & product write up
                // also ensure that result is not empty
                if (res?.data?.result?.length !== 2 || res?.data?.result[0]?.length === 0) {
                    setMongoData([]);
                } else {
                    const premiumArr = sortArrayOfObjectsByKey(res.data.result[0], "insurer", "asc");// array that contains all premium data point from mongo
                    const productArr = res.data.result[1];  // array that contains all product info for this category 

                    const combineArrData = premiumArr.map(entry => {

                        // getting the insurer & plan name to match the corresponding product info
                        const insurer = entry.insurer.trim();
                        const plan = entry.planName.split('(', 2)[0].trim();

                        // to find the corresponding product info
                        const entryInfo = productArr.filter(p => p.insurerName === insurer && p.planName === plan)// end filter

                        return entryInfo.length > 0
                            ? { ...entry, details: entryInfo[0], promo: entryInfo[0]?.customerPromo?.valid }
                            : { ...entry, details: {}, promo: false, }

                    }) // end map
                    // console.log("At getLongTermCareData in useEffct => combine data: ", combineArrData)
                    setMongoData(combineArrData);
                    setLoading(false);
                }
            } catch (error) {
                // console.log(error)
                messages.current.show({ severity: 'error', detail: `Error: Backend server is down`, sticky: true });
                setLoading(false);
            }
        }
        getLongTermCareData();
        // only run once when component is mounted    
    }, []) // eslint-disable-line 

    // formik
    const validationSchema = yup.object(
        searchBySA
            ? {
                sumInsured: yup.number().typeError("Please enter a number").test(
                    "endsWith00",
                    "Sum insured must a multiple of 100",
                    (value) => value % 100 === 0,
                ),
                premium: yup.number()
            }
            : {
                sumInsured: yup.number(),
                premium: yup.number().typeError("Please enter a number").required("Please enter the premium")
            }
    );

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => handleSubmit(values)
    })

    const handleSubmit = (values) => {

        // console.log("values @ handleSubmit", values)
        // console.log("mongoData @ handleSubmit", mongoData)
        const { searchBySA, _2ADLs, _3ADLs, _escalation, sumInsured, premium } = values;
        // setQuery({ ...values, anb: user?.comparisonInsured?.ANB, gender: user?.comparisonInsured?.gender[0] });

        // ensure that at least one claim conditition
        if (!_2ADLs && !_3ADLs) {
            alert("Please select minimum one claim condition");
            return;
        }

        // filter 2ADL and/or 3ADL & store in display
        let display = (_2ADLs && !_3ADLs)
            ? /*mongoDataAfterFilter*/mongoData.filter(ele => ele["uniqueID"].includes("2ADL")) // only requires 2ADL plan
            : (!_2ADLs && _3ADLs)
                ? /*mongoDataAfterFilter*/mongoData.filter(ele => ele["uniqueID"].includes("3ADL")) // only requires 3ADL plan
                : [.../*mongoDataAfterFilter*/mongoData]; // requires both 2ADL & 3ADL plan

        // remove escalation options if _escalation is false
        if (!_escalation) {
            display = display.filter(ele => !ele["plan_shortName"].includes("%"))
        }

        // console.log("display @ handleSubmit", display)
        if (searchBySA) {
            display = display.map(ele => {
                const SA = ele.values.filter(record => record.sumInsuredComparison === +sumInsured)
                // proceed only if record is NOT an empty object
                if (SA.length > 0) {
                    const obj = { ...ele, ...SA[0] };
                    delete obj.values;
                    return obj;
                } else {
                    return null
                }
            })
        } else {
            display = display.map(ele => {
                // sort the array with premium from highest to lowest
                const sortPremium = (ele.values).sort((a, b) => (a.premiumTotal < b.premiumTotal) ? 1 : -1)
                for (let i = 0; i < sortPremium.length; i++) {
                    // once the plan premium is lower than the input premium
                    if (sortPremium[i].premiumTotal <= +premium) {
                        const obj = { ...ele, ...sortPremium[i] };
                        delete obj.values;
                        return obj; // this will exit for loop
                    }
                }
                return null
            })
        }
        // removing all null & undefined
        display = display.filter(e => e !== null);

        const allowedInsurers = user?.comparisonInsurerDisplaySettings.find(ele => ele.comparisonType === userContext_longTermCare)?.onlyDisplayInsurers;
        // console.log("@ handleSubmit => allowedInsurers", allowedInsurers);

        (allowedInsurers && allowedInsurers.length > 0)
            ? setDisplayData(() => display.filter(ele => allowedInsurers.includes(ele.insurer)))
            : setDisplayData(() => display); // use the mongoData if allowedInsurers.length === 0 (display all insurers)

        setReadyToSearch(false);
        setShowEditButton(true);

        // console.log(JSON.stringify(lastSearchParameter));
        // console.log(JSON.stringify(values));
        (JSON.stringify(lastSearchParameter) !== JSON.stringify(values)) && setLastSearchParameter(values);
        scrollToTop();
    }

    useEffect(() => {
        // only update the displayData if the lastSearchParameter is not empty (search more than once)
        (Object.keys(lastSearchParameter).length > 0) && handleSubmit(lastSearchParameter);
    }, [user]) // eslint-disable-line

    const handleReset = () => {
        //formik.handleReset();
        formik.setFieldValue("sumInsured", "");
        formik.setFieldValue("premium", "");
        formik.setFieldValue("_2ADLs", false);
        formik.setFieldValue("_3ADLs", false);
        formik.setFieldValue("_escalation", false);
        setDisplayData([]);
        setReadyToSearch(true);
        setShowEditButton(false);
        // setQuery({});
    }

    const handleEdit = () => {
        setReadyToSearch(true);
        setShowEditButton(false);
    }

    // handle tab change
    const handleTabChange = (index) => {
        setActiveTabIndex(index)

        const BySumInsured = index === 0 ? true : false;
        formik.setFieldValue("searchBySA", BySumInsured)
        setSearchBySA(BySumInsured)
    }

    const generateComparison = useCallback(() => {

        // functions to format data in columns
        const formatSA = (value) => "$" + formatDollar(value.sumInsured);
        const formatPremiumTerm = (value) => value.premiumTerm + " Yrs";
        const formatCurrency = (value) => value.premiumTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        const formatPromo = (value) => value.promo ? "Y" : "";

        // function to expand all and collapse all expanders
        const collapseAll = () => setExpandedRows(null);

        const expandAll = () => {
            let _expandedRows = {};
            displayData.forEach(p => _expandedRows[`${p._id}`] = true);
            setExpandedRows(_expandedRows);
        }

        // filter function for key search
        const onGlobalFilterChange2 = (e) => {
            const value = e.target.value;
            let _filters2 = { ...filters2 };
            _filters2['global'].value = value;

            setFilters2(_filters2);
            setGlobalFilterValue2(value);
        }

        const renderHeader = () => <>
            <div className="flex justify-content-between">
                <span>
                    <Button icon="pi pi-plus" label={mobileScreen ? "" : "Expand All"} onClick={expandAll} className="mr-2" />
                    <Button icon="pi pi-minus" label={mobileScreen ? "" : "Collapse All"} onClick={collapseAll} />
                </span>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        placeholder={mobileScreen ? "Search" : "Keyword Search"}
                        className={mobileScreen ? "p-inputtext-sm" : ""}
                        value={globalFilterValue2}
                        onChange={onGlobalFilterChange2}
                    />
                </span>
            </div>
        </>

        const rowExpansionTemplate = (data) => <DisplayProductDetails data={data} />

        return <div className='col-12'>
            <div className='card'>
                <DisplayComparisonTitle type="Long Term Care" />
                <Suspense fallback={<div>Loading Comparison Table...</div>}>
                    <DataTable value={displayData}
                        stripedRows
                        responsiveLayout="scroll"

                        // header
                        header={renderHeader()}

                        //footer
                        footer={<DataTableFooter type="longTermCare" query={formik.values} />}

                        // expander props
                        expandedRows={expandedRows}
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        rowExpansionTemplate={rowExpansionTemplate}
                        dataKey="_id"

                        // keyword search props
                        filters={filters2} filterDisplay="row"

                    >
                        <Column expander style={{ width: '3em' }} />
                        <Column field="insurer_shortName" header="Insurer" sortable></Column>
                        <Column field="plan_shortName" header="Plan" sortable></Column>
                        <Column field="type" header="Type" sortable></Column>
                        <Column field="sumInsured" header="Insured Amt" body={formatSA} sortable></Column>
                        <Column field="premiumTerm" header="Premium Term" body={formatPremiumTerm} sortable></Column>
                        <Column field="premiumTotal" header="Annual Premium" body={formatCurrency} sortable></Column>
                        <Column field="promo" header="Promo" body={formatPromo} sortable></Column>
                    </DataTable>
                </Suspense>
            </div>
        </div>

    }, [displayData, expandedRows, mobileScreen, filters2, globalFilterValue2, formik.values])

    // console.log("mongoDataAfterFilter", mongoDataAfterFilter)

    return (
        <div className='grid'>
            {
                !readyToSearch && generateComparison()
            }
            <div className='col-12 md:col-6'>
                <div className='card'>

                    <Messages ref={messages} />

                    <TabView activeIndex={activeTabIndex} onTabChange={(e) => handleTabChange(e.index)}>
                        <TabPanel header="By Sum Insured" disabled={!readyToSearch}>
                            {displayFormikLabel("sumInsured", "Sum Insured (in multiple of 100):", formik.touched, formik.errors)}
                            <InputText id="sumInsured" type="text" value={formik.values.sumInsured} onChange={(e) => formik.setFieldValue("sumInsured", e.target.value)} className="w-full" disabled={!readyToSearch} placeholder="E.g. 1000" />
                        </TabPanel>
                        <TabPanel header="By Premium" disabled={!readyToSearch}>
                            {displayFormikLabel("premium", "Max premium", formik.touched, formik.errors)}
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">$</span>
                                <InputText id="premium" type="text" value={formik.values.premium} onChange={(e) => formik.setFieldValue("premium", e.target.value)} className="w-full" disabled={!readyToSearch} placeholder="E.g. 800" />
                            </div>
                        </TabPanel>
                    </TabView>
                    <h5>Claim conditions</h5>
                    <div className="field-checkbox">
                        <Checkbox
                            name="_2ADLs"
                            value={formik.values["_2ADLs"]}
                            onChange={e => formik.setFieldValue("_2ADLs", e.checked)}
                            checked={formik.values["_2ADLs"]}
                            disabled={!readyToSearch} />
                        <label htmlFor="_2ADLs">Unable to perform 2ADLs</label>
                    </div>
                    <div className="field-checkbox">
                        <Checkbox
                            name="_3ADLs"
                            value={formik.values["_3ADLs"]}
                            onChange={e => formik.setFieldValue("_3ADLs", e.checked)}
                            checked={formik.values["_3ADLs"]}
                            disabled={!readyToSearch} />
                        <label htmlFor="_3ADLs">Unable to perform 3ADLs</label>
                    </div>
                    <h5>Includes escalation benefit options</h5>
                    <div className="field-checkbox">
                        <Checkbox
                            name="_escalation"
                            value={formik.values["_escalation"]}
                            onChange={e => formik.setFieldValue("_escalation", e.checked)}
                            checked={formik.values["_escalation"]}
                            disabled={!readyToSearch} />
                        <label htmlFor="_escalation">{`Yes (Note: Premium is not level and will increase yearly)`}</label>
                    </div>

                    <div className='mt-3'>
                        <hr></hr>
                        <div className='grid mt-3'>
                            <div className={showEditButton ? 'col-12 md:col-4' : 'col-12 md:col-6'}>
                                <Button type="button" label="Reset" aria-label="Reset" className='w-full p-button-outlined' onClick={handleReset} />
                            </div>
                            {
                                showEditButton && <div className='col-12 md:col-4'>
                                    <Button type="button" label="Edit" aria-label="Reset" className='w-full p-button-secondary' onClick={handleEdit} />
                                </div>
                            }
                            <div className={showEditButton ? 'col-12 md:col-4' : 'col-12 md:col-6'}>
                                <Button type="submit" label={loading ? "Loading Data..." : "Search"} aria-label="Search" className='w-full' disabled={loading || !readyToSearch} onClick={formik.handleSubmit} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-12 md:col-6'>
                <div className='card'>
                    <DisplayInsuredDetails />
                    <FormInsurerDisplayedOption comparisonType={userContext_longTermCare} insurerList={insurer_longTermCare} />
                </div>
            </div>
        </div >
    )
}

export default CompareLongTermCare