import React, { useState } from 'react'

// custome components
import Stepper from '_dashboard/modules/calculator/calculatorCoverage/death/Stepper_Death';
import ClientDetails from '_dashboard/modules/calculator/calculatorCoverage/death/ClientDetails';
import CoverageRequired from '_dashboard/modules/calculator/calculatorCoverage/death/CoverageRequired';
import CurrentCoverage from '_dashboard/modules/calculator/calculatorCoverage/death/CurrentCoverage';
import CoverageGap from '_dashboard/modules/calculator/calculatorCoverage/death/CoverageGap';

const DeathCoverageCalculator = () => {

    // state
    const [activeIndex, setActiveIndex] = useState(0); // states for stepper
    const [clientDetails, setClientDetails] = useState({}); // states for client details
    const [coverageRequired, setCoverageRequired] = useState([]); // states for coverage required
    const [currentCover, setCurrentCover] = useState([]) // states for current cover
    const [coverGap, setCoverGap] = useState([]) // states for cover gap

    // todo: useEffect to update coverage gap when current cover or coverage required changes

    return <div className="mt-3">

        <Stepper activeIndex={activeIndex} setActiveIndex={setActiveIndex} readOnly={false}/>
        {
            activeIndex === 0 && <ClientDetails clientDetails={clientDetails} setClientDetails={setClientDetails} />    
        }
        {
            activeIndex === 1 && <CoverageRequired coverageRequired={coverageRequired} setCoverageRequired={setCoverageRequired} />
        }
        {
            activeIndex === 2 && <CurrentCoverage currentCover={currentCover} setCurrentCover={setCurrentCover} />
        }
        {
            activeIndex === 3 && <CoverageGap coverGap={coverGap} setCoverGap={setCoverGap} />
        }
    </div>
}

export default DeathCoverageCalculator