import React, { useState, useRef } from 'react'

// prime react
import { TabView, TabPanel } from 'primereact/tabview';

// custome components
import DeathCoverageCalculator from '_dashboard/modules/calculator/calculatorCoverage/death/DeathCoverageCalculator';

const CoverageCalculator = () => {

  // storing the index of active tab (0 for death and 1 for CI)
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  // ref to focus on form
  const inputRef = useRef(null);

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          <h5>Coverage Calculator</h5>
          <ul>
            <li>Calculate coverage needed upon death and total permanent disability (TPD)</li>
            <li>Caluclate coverage needed upon diagnose of critical illnesses (e.g. cancer or heart attack)</li>
          </ul>
        </div>
      </div>
      <div className="col-12">
        <div className="card" ref={inputRef}>
          <TabView activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)}>
            <TabPanel header="Death/TPD Calculator" >
                <DeathCoverageCalculator />
            </TabPanel>
            <TabPanel header="CI Calculator">
              <h5>CI Calculator</h5>
            </TabPanel>
          </TabView>
        </div>
      </div>
    </div>
  )
}

export default CoverageCalculator